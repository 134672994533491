@import "mixin.scss";
@import "color.scss";
@import "breakpoint.scss";

.border-address{
    @include border-box($gradient-color, 1.5px, 25px, 0.5);
    height: 40px !important;
}
.wallet-address-label-pc-view {
    font-family: 'KardustExpandedRegular';
    font-weight: 500;
    font-size: 15px !important;
}
.transaction-table-header {
    font-family: 'KardustCondensedRegular' !important;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 23px;
}
.mana-table-header {
    font-family: 'Kardust Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    th{
        @include media-breakpoint-up("lg") {
            min-width: 100px;
        }
    }
}
.transaction-table-body {
    font-family: 'KardustCondensedLight' !important;
    font-weight: 500;
    font-style: normal;
    font-size: 15px !important;
    line-height: 19.15px;
}
.mana-table-body {
    font-family: 'Kardust Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.wallet-link-pc-view {
    font-family: 'KardustExpandedRegular' !important;
    font-weight: 500;
    font-size: 14px !important;
}
.transaction-header-pc{
    font-family: 'KardustExpandedRegular' !important;
    font-weight: 500;
    font-size: 20px !important;
    // @include text-gradient($gradient-color);
    color: $badge-bg-color;
    line-height: 25.9px;
}
.pagination-pc{
    font-family: 'Kardust' !important;
    font-weight: 600;
    font-size: 18px !important;
    // @include text-gradient($gradient-color);
    color: $badge-bg-color !important;
    line-height: 23.04px;
}
.transaction-header-amount-pc{
    font-family: 'Kardust' !important;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 25.9px;
}
.disable-text-color {
    color:grey;
}
.disable-icon {
    filter: grayscale(0.20);
}
.table-row-data {
    white-space: nowrap;
}

.hover-effect {
    .active-text {
        background-size: 100%;
        background-repeat: repeat;
        background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .wallet-icon-active {
        width: 25px;
        height: 25px;
        fill: $badge-bg-color !important;

        path {
            fill: $badge-bg-color !important;
        }
    }

    .active-span {
        span {
            color: $badge-bg-color;
        }
    }
}

.receive {
    .title {
        font-size: 20px;
        font-weight: 500;
        font-family: 'KardustExpanded';
        color: $badge-bg-color;
    }
}

.wallet-item {
    height: 30px;
    justify-content: center;
    display: flex;

    @include media-breakpoint-up('xl') {
        &:nth-of-type(6) {
            padding-right: 0 !important;
        }
    } 
}

.wallet-sp-item {
    height: 40px;
}
        
.wallet-item-icon {
    width: 25px !important;
    height: 25px !important;
}

.wallet-anima-icon {
    width: 40px;
    height: 40px;
}

.wallet-send-icon {
    width: 30px !important;
    height: 30px !important;
}

.wallet-circle-icon {
    width: 15px;
    height: 15px;
}

.arcana-send-icon {
    path {
        fill: $badge-bg-color;
    }
}

.active-icon {
    circle {
        fill: $badge-bg-color;
    }
    path {
        fill: #19162D;
    }
}

.wallet-table {
    table {
        border-collapse: separate;
        border-spacing: 0 5px;
        td {
            padding-right: 10px;

            &:nth-of-type(3) {
                max-width: 370px;
                width: 300px;
            }

            &:nth-of-type(5) {
                max-width: 350px;
                width: 300px;
                text-align: left;
            }
        }
    }
}

.wallet-transaction-swal {
    background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%) !important;

    .swal2-popup{
        height: 97vh;
    }

    .swal2-html-container {
        margin: 0 !important;

        .swal-react-modal {
            div {
                margin-bottom: 25px !important;
                span {
                    font-size: 20px;
                    color: #82808C;
                    &:nth-child(1) {
                        width: fit-content; 
                        margin-bottom: 10px;     
                        padding-bottom: 5px;  
                    }
                }
            }
        }
    }
    .swal2-actions {
        width: 80%;
        height: 55px;
        background: $gradient-color !important;
        border-radius: 15px;
        border-color: transparent;
        box-shadow: none;
        .swal2-styled.swal2-confirm {
            color:white;
            margin: 0;
            width: 100%;
            height: 55px;
            font-size: 25px;
            border-radius: 15px;
            border-width: 0 !important;
            border-color: none !important;
            background: $gradient-color;
            box-shadow: none;
            line-height: 1;
        }
    }
}

.qr-code-100 {
    max-width: 100%;
    width: 100%;
}

.choose-btn {
    z-index: 40;
    width: 300px;
    @include media-breakpoint-down('lg') {
        width: calc(100% - 32px);
        bottom: 105px;
        position: fixed;
        padding-top: 1rem;
    }
}

.wallet-pc-badge {
    margin-left: 5px; 
    background: red !important;
    width: 21px; 
    height: 21px; 
    border-radius: 50%; 
    font-family: 'Kardust Expanded'; 
    font-weight: 500;
    color: rgba(255, 255, 255, 0.91) !important;    
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    right: -28px; 
    top: 2px; 
    padding-bottom: 2px;
}

.wallet-badge {
    background: red; 
    width: 22px; 
    height: 22px; 
    border-radius: 50%; 
    position: absolute; 
    right: -17px; 
    top: 0; 
    font-family: 'Kardust Expanded'; 
    font-weight: 500;
    color: rgba(255, 255, 255, 0.91);
}

.menu-card {
	max-width: 280px;
	width: 270px;
	.card {
		width: 100%;
		height: 115px;
		background-repeat: no-repeat;
		margin-right: 5px;

		.card-body {
			// @include flex-center;
			font-family: KardustCondensed;
			font-size: 25px;
			flex-direction: column;
			line-height: 40px;

			// span {
			// 	align-self: flex-start;
			// }

			.text {
				font-size: 20px !important;
				font-family: 'KardustCondensed';
				font-weight: 500;
				line-height: 28px;
			}

			@include media-breakpoint-down('sm') {
				.text {
					font-size: 20px !important;
					line-height: 16px !important;
				}
			}

			.number {
				font-size: 22px;
				font-family: "Kardust";
				font-weight: 400;
				line-height: 25px;
			}
		}

		&.w-fixed {
			width: 145px;
		}

		&.arcana-menu,
		&.persona-menu,
		&.anima-menu,
		&.levias-menu,
		&.token-menu,
		&.coming-soon-menu,
		&.security-token-menu {
			position: relative;

			&::before {
				content: "";
				position: absolute !important;
				width: 100%;
				height: 100%;
				border-radius: 10px;
				background-repeat: no-repeat !important;
				background-position-x: right !important;
				background-position-y: center !important;
				mix-blend-mode: normal;
				z-index: 0;
			}

			&::after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				border-radius: 8px;
			}

			.card-body {
				z-index: 1;
				justify-content: space-between;
				@include media-breakpoint-up('lg') {
					justify-content: space-evenly;
				}
				align-items: flex-start;
				min-height: 70px !important;

				span {
					font-weight: 500;
				}
			}
		}

		&.arcana-menu {
			border: 2px solid #0358bbcc;

			&::before {
				background-image: url(../../assets/icons/arcana_menu.svg);
				background-size: contain;
				@include border-box(linear-gradient(179.61deg,
						rgba(3, 88, 187, 0) 0.34%,
						rgba(3, 88, 187, 0.2) 80.23%),
					1.5px,
					10px);
			}

			&::after {
				background: linear-gradient(179.61deg, rgba(3, 88, 187, 0) 0.34%, rgba(3, 88, 187, 0.2) 80.23%);
				box-shadow: inset 0px 0px 3px 0px #0358bbcc;
			}
		}

		&.persona-menu {
			// opacity: 0.6;
			border: 2px solid rgba(255, 255, 1, 0.8);

			&::before {
				mask-image: url(../../assets/icons/persona_menu.svg);
				mask-repeat: no-repeat;
				mask-position: right;
				transition: background-color 200ms;
				background-color: #ffff01; 
				padding: 0 !important;
				opacity: 0.3;
				@include border-box(linear-gradient(179.61deg,
						rgba(3, 88, 187, 0) 0.34%,
						rgba(3, 88, 187, 0.2) 80.23%),
					1.5px,
					10px);

				@include media-breakpoint-up('lg') {
					mask-size: contain;
				}
			}

			&::after {
				background: linear-gradient(179.61deg, rgba(255, 255, 1, 0) 0.34%, rgba(255, 255, 1, 0.15) 80.23%);
				box-shadow: inset 0px 0px 3px 0px rgba(255, 255, 1, 0.8);
			}
		}

		&.anima-menu {
			border: 2px solid #5AA06E;
			&::before {
				background-image: url(../../assets/icons/anima_menu.svg);
				background-size: contain;
				@include border-box(linear-gradient(180deg, rgba(114, 173, 136, 0.19) 0%, rgba(90, 160, 110, 0.48) 100%),
					1.5px,
					10px);
			}

			&::after {
				background: linear-gradient(180deg, rgba(114, 173, 136, 0.19) 0%, rgba(90, 160, 110, 0.48) 100%);
				box-shadow: inset 0px 0px 3px 0px #6ad6cf;
			}
		}
		&.levias-menu {
			border: 2px solid $levica-green;
			// border: 2px solid rgba(89, 89, 89, 0.6);

			&::before {
				background-image: url(../../assets/icons/levias_menu.svg);
				// background-size: contain;
				background-size: 63%;
				@include border-box(linear-gradient(180deg, rgba(68, 165, 158, 0.04) 0%, rgba(68, 165, 158, 0.49) 100%),
					1.5px,
					10px);
			}

			&::after {
				// background: linear-gradient(180deg, rgba(217, 217, 217, 0.1) 0%, rgba(217, 217, 217, 0.1) 100%);
				background: linear-gradient(180deg, rgba(68, 165, 158, 0.04) 0%, rgba(68, 165, 158, 0.49) 100%);
				box-shadow: inset 0px 0px 3px 0px #5fd8cf;
			}
		}

		&.coming-soon-menu {
			border: 2px solid #d9d9d980;
			// filter: blur(1px);
			border-radius: 10px;
			color: #c0c0c0;
			box-shadow: inset 0px 0px 3px 0px #d9d9d980;
			background-color: transparent;

			.card-body {
				text-align: center;
				margin-top: -3px;

				.text {

					padding-top: 13px;
				}
			}

			span {
				font-family: 'Kardust', 'Condensed';
				;
				font-size: 22px !important;
				font-weight: 500 !important;
				width: 100%;
			}
		}

		&.security-token-menu {
			opacity: .4;
			text-transform: uppercase;
			border: 2px solid rgba(217, 217, 217, 0.8);

			&::before {
				background-image: url(../../assets/icons/security_token.svg);
				// @include border-box(linear-gradient(99.22deg, rgba(232, 94, 255, 0.21) 2.62%, rgba(45, 217, 255, 0.25) 96.77%),
				//   1.5px,
				//   10px);
				@include media-breakpoint-up('lg') {
					background-size: contain;
				}
			}

			&::after {
				// background: linear-gradient(99.22deg, rgba(232, 94, 255, 0.21) 2.62%, rgba(45, 217, 255, 0.25) 96.77%);
				// box-shadow: inset 0px 0px 3px 0px #6ad6cf;
				background: rgba(217, 217, 217, 0.2);
				box-shadow: inset 0px 0px 3px 0px rgba(217, 217, 217, 0.2);
			}
		}

		&.token-menu {
			border: 2px solid #e074b5;

			&::before {
				background-image: url(../../assets/images/token.svg);
				@include media-breakpoint-up('lg') {
					background-size: contain;
				}
			}

			&::after {
				background: linear-gradient(213deg, rgba(224, 116, 181, 0) 60%, rgba(224, 116, 181, 0.4) 100%);
				box-shadow: inset 0px 0px 3px 0px #e074b5;
			}
		}

		@include media-breakpoint-down('sm') {
			width: 160px;
			height: 70px;

			.card-body {
				padding: 13px;
				width: 160px;
				height: 70px;
			}
		}
	}

	@include media-breakpoint-down('sm') {
		width: 50%;

		&:nth-of-type(odd) {
			padding: 0 !important;
		}

		&:nth-of-type(even) {
			padding: 0 !important;
		}
	}
}

@media screen and (max-width: 768px)  {
    .card-mr-3 {
        margin-right: 10px;
    }
}

@media screen and (min-width: 1024px)  {
    .card-mr-3 {
        margin-right: 10px;
    }
}
