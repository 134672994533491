@import "./app/color.scss";
@import "./app/breakpoint.scss";
@import "./app/mixin.scss";
@import "./app/common.scss";

.account-icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #808080;
}

.font-weight-bold{
    font-weight: bolder;
}

.border-transparent{
    border-color: transparent;
}

.edit-profile-pic{
    position: relative;
}

.edit-profile-pic-icon{
    position: absolute;
    right: 0;
    bottom: 0;
}

.grey-screen{
    background-color: #808080;
    height: 70px;
    border-radius: 15px;
}

.pincode-input-container{
    display: flex;
    justify-content: space-between;
    max-width: 350px;
    margin: 0 auto;

    margin-top:66px;
    margin-bottom:44px;

}
.code-d-wrapper {
    max-width: 450px;
    text-align: center;
    font-family: "Kardust" !important;

    .pin-input-field,input {
        width: 90% !important;
        height: 43px !important;
        border-radius: 10px !important;
        background: transparent !important;
        border: 1px solid rgba(217, 217, 217, 0.5) !important;
        text-align: center !important;
        color: #BF96FF !important;
        letter-spacing: 20px;
        margin: 20px auto;

        &::placeholder {
            color: rgba(217, 217, 217, 0.5) !important;
        }
    }
    .pin-input-field:focus, .pin-input-field:hover, .pin-input-field.active  {
        border: 1px solid #BF96FF !important;
        outline: none;
    }

    .code-d-question {
        color: #BF96FF;
    }

    .about-content {
        font-size: 18px;
    }
}

.clip-left-bg-white {
    height: 100vh;
    background: url(../assets/icons/clip-top-left.svg), url(../assets/icons/clip-bottom-right.svg);
    background-repeat: no-repeat, no-repeat;
    background-size: 74%, 74%;
    background-position: 0px 0px, 100% 100%;

    @include media-breakpoint-up('lg') {
        background-size: 40%, 40%;
    }
}

.clip-left-bg-blue {
    height: 100vh;
    background: url(../assets/images/code-d-bg.png);
    // background: url(../assets/icons/clip-path-top.svg), url(../assets/icons/clip-path-bottom.svg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: 0px 0px, 100% 100%;
    background-color: #01072c;
    @include media-breakpoint-down('lg') {
        background: url(../assets/images/code-d-bg-sp.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.code-d-w-wrapper {
    max-width: 450px;
    text-align: center;
    color: #595959;
    font-family: 'Cambay' !important;

    .pin-input-field,input {
        width: 90% !important;
        height: 43px !important;
        background: transparent !important;
        border: 1px solid #22874A !important;
        text-align: center !important;
        color: #22874A !important;
        letter-spacing: 20px;
        margin: 20px auto;

        &::placeholder {
            color: #22874A !important;
        }
    }
    .pin-input-field:focus, .pin-input-field:hover, .pin-input-field.active  {
        border: 1px solid #22874A !important;
        outline: none;
    }

    .code-d-question {
        color: #22874A;
    }

    .btn-code-d-cancel {
        height: 45px;
        border: 1px solid #22874A !important;
        color: #22874A;
        background: transparent;
    }

    .bg-green {
        background: #22874A;
        color: #fff !important;
    }
    .about-content {
        font-size: 20px;
    }
}

.code-d-b-wrapper {
    max-width: 450px;
    text-align: center;
    color: #fff;
    font-family: 'Cambay' !important;

    .pin-input-field,input {
        width: 90% !important;
        height: 43px !important;
        background: transparent !important;
        border: 1px solid #00a8ff !important;
        text-align: center !important;
        color: #fff !important;
        letter-spacing: 20px;
        margin: 20px auto;

        &::placeholder {
            color: #00a8ff !important;
        }
    }
    .pin-input-field:focus, .pin-input-field:hover, .pin-input-field.active  {
        border: 1px solid #00a8ff !important;
        outline: none;
    }

    .code-d-question {
        color: #00a8ff;
    }

    .btn-code-d-cancel {
        height: 45px;
        border: 1px solid #00a8ff !important;
        color: #00a8ff;
        background: transparent;
    }

    .bg-green {
        background: #00a8ff;
        color: #fff !important;
    }
    .about-content {
        font-size: 20px;
    }
}

.contact-btn {
    max-width: 330px !important;
    width: 100%;
    height: 50px !important;
    line-height: 15px !important;
}

.contact-btn:disabled {
    border-color: #d9d9d9 !important;
    color: #d9d9d9 !important;
}